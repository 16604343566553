import renee from '../assets/resources/Renee baltine.png';
import adam from '../assets/resources/Adam savage.png';
import chris from '../assets/resources/Chris slaugh.png';
import hexagonpdf from '../assets/home-v3/Hexagon.pdf';
import coritypdf from '../assets/home-v3/Cority.pdf';
import miller from '../assets/resources/markmiller.png';
import venafipdf from '../assets/resources/Venafi.pdf';
import Trintechpdf from '../assets/resources/Trintech.pdf';
import corityThumbnail from '../assets/resources/coritycasestudy.png';
import hexagonThumbnail from '../assets/resources/hexagoncasestudy.png';
import everbridgeThumbnail from '../assets/resources/everbridgecasestudy.png';
import venafiThumbnail from '../assets/resources/venaficasestudy.png';
import trintechthumbnail from '../assets/resources/trintech-thumbnail.png';
import jimFischer from '../assets/resources/trintech.png';

const caseStudyData = {
    resourceData: [
        {
            id: 'trintech',
            caseStudyTitle: 'Trintech',
            companyLogo: trintechthumbnail,
            title: 'Boosting Efficiency and Customer Satisfaction',
            author: {
                image: jimFischer,
                name: '<span>jim</span> Fischer',
                title: 'Vice President, Support and Dataflow Services',
                quote: `AptEdge isn't just about aggregating data from different sources. Its ability to synthesize data into relevant, accurate, and well-structured answers significantly decreases the agent's effort and time necessary to deliver a solution, which is of great value to us.`
            },
            stats: [
                {
                    percentage: '14%',
                    title: 'reduction in Resolution Time'
                }
            ],
            pdf: Trintechpdf,
            ctaTitle: 'Download PDF',
            count: [],
            countColor: '#E75500'
        },
        {
            id: 'venafi',
            caseStudyTitle: 'Venafi',
            companyLogo: venafiThumbnail,
            title: 'Solving for Information Sprawl',
            author: {
                image: miller,
                name: '<span>Mark</span> Miller',
                title: 'Sr. Director, Enterprise Security Support',
                quote: `How am I going to find this answer? Am I going to
            pull up these eight different resources, pull up eight
            windows and go search the same terms in each
            area? 
            I have AptEdge, which is one. It was an eight fold
            gain of time.`
            },
            stats: [
                {
                    percentage: '8x',
                    title: 'Seach Efficiency'
                }
            ],
            pdf: venafipdf,
            ctaTitle: 'Download PDF',
            count: [],
            countColor: '#E75500'
        },
        {
            id: 'hexagon',
            caseStudyTitle: 'Hexagon',
            companyLogo: hexagonThumbnail,
            title: 'Agent Efficiency to Streamline Support',
            author: {
                image: adam,
                name: '<span>Adam</span> Savage',
                title: 'Chief Operating Officer',
                quote: `Our agent efficiency is up by 50% with AptEdge, reducing headcount and onboarding costs`
            },
            stats: [
                {
                    percentage: '50%',
                    title: 'Increase in Support Agent Efficiency'
                },
                {
                    percentage: '60%',
                    title: 'More access to information'
                },
            ],
            pdf: hexagonpdf,
            ctaTitle: "Download Pdf",
            count: [
                {
                    num: '730B',
                    title: 'industry size'
                },
                {
                    num: '50+',
                    title: 'countries'
                },
                {
                    num: '170',
                    title: 'acquisitions'
                },
                {
                    num: '24,000+',
                    title: 'employees'
                }
            ],
            countColor: '#4BA5C4'
        },
        {
            id: 'ever-bridge',
            caseStudyTitle: 'Everbridge',
            companyLogo: everbridgeThumbnail,
            title: 'Earning High CSAT with The Right Answers at the Right Time',
            author: {
                image: renee,
                name: '<span>Renee</span> Batine',
                title: 'Sr. Dir. Global Customer Support',
                quote: `Our customer satisfaction rates have been 99% ever since we put AptEdge in use.`
            },
            stats: [
                {
                    percentage: '99%',
                    title: 'Achieved CSAT'
                }
            ],
            pdf: "https://www.aptedge.io/blog/everbridge-achieves-99-customer-satisfaction-with-help-from-aptedge",
            ctaTitle: "Read Case Study",
            count: [],
            countColor: '#E75500'
        },
        {
            id: 'cority',
            caseStudyTitle: 'Cority',
            companyLogo: corityThumbnail,
            title: 'Enhancing Agent Time to Productivity',
            author: {
                image: chris,
                name: '<span>Chris</span> Slaugh',
                title: 'Former VP, Global Customer Support',
                quote: `Now our brand new analysts come on board and they hit that time to productivity number within two months. So
                    that's almost 70% improvement in our time to productivity.`
            },
            stats: [
                {
                    percentage: '75%',
                    title: 'Improvement to Analysis Time'
                },
                {
                    percentage: '67%',
                    title: 'Improvement to Time to Productivity'
                },
                {
                    percentage: '12%',
                    title: 'Improvement to Resolution Time'
                }
            ],
            pdf: coritypdf,
            ctaTitle: "Download Pdf",
            count: [
                {
                    num: '1,600+',
                    title: 'global customers'
                },
                {
                    num: '120+',
                    title: 'countries'
                },
                {
                    num: '2M+',
                    title: 'end users'
                },
                {
                    num: '600+',
                    title: 'employees'
                }
            ],
            countColor: '#E75500'
        },
    ],
}

export default caseStudyData;